@media screen and (max-width: 1024px) {
  body {
    zoom: 0.7;
  }
}

@media screen and (max-width: 500px) {
  /*Jack*/
  .w-100 {
    height: auto !important;
  }

  .center-main-div {
    width: auto !important;
    height: auto !important;
  }

  .carousel {
    height: auto !important;
  }

  .initial-main-div {
    margin-top: 84px !important;
  }
  .header-pnl {
    height: 72px !important;
  }
  .header-pnl-logo {
    width: 150px !important;
    height: 72px !important;
  }
  .slider-container {
    width: auto !important;
    margin-top: 78px !important;
    // height: 22vh !important;
  }
  .second-main-div {
    margin-top: 6vh !important;
  }

  .img-in-menu {
    box-shadow: none !important;
  }
  .desk-side-menu {
    display: none;
  }
  .cuisine {
    zoom: 0.9 !important;
  }
  .item-cat-box {
    padding-left: 0px !important ;
  }
  .item-cat-title {
    padding-left: 0px !important ;
  }
  .img-item-box {
    // width: 172px !important;
    // height: 150px !important;
    // margin-left: 16vw !important;
    // position: relative;
  }
  .img-in-menu {
    width: 100% !important;
    height: 100px !important;
  }
  .footer-in-menu {
  }
  .footer-in-menu-text {
    font-size: 14px !important;
  }
  .img-cost-div {
    height: 20px !important;
    font-size: 14px !important;
  }
  .cart {
  }
  .cuisine {
    margin-left: 0px !important;
    width: 90% !important;
  }
  .item-row {
  }
  .item-name {
  }
  .item-descr {
  }
  .item-add {
  }
  .cart-title {
    padding-left: 1em !important;
  }
  .cart-table {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
  .address-container {
    width: auto !important;
  }
  .invoice-container {
    width: auto !important;
  }
  .invoice-distrib {
    padding-left: 2em !important;
    padding-right: 0.5em !important;
  }
  .invoice-btns-container {
    padding-left: 2em !important;
    padding-right: 1.5em !important;
  }
}
@media screen and (min-width: 366px) and (max-width: 500px) {
  .img-menu-container {
  }
}

@media screen and (max-width: 365px) {
  .img-menu-container {
  }
}
@media screen and (max-width: 800px) {
  .img-item-box {
  }
  .img-in-menu {
  }
  .footer-in-menu {
  }
}
@media screen and (min-width: 1024px) {
  .slider-container {
    position: relative !important;
    float: left !important;
  }

  .flt-btn {
    display: none !important;
  }
  .address-container {
    position: relative !important;
    float: left !important;
  }
  .invoice-container {
    position: relative !important;
    float: left !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .img-menu-container {
  }
}
body {
  margin: 0px !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.parent {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: hidden; /* Add vertical scrollbar */
  /*position:absolute;*/
  width: 100%;

  /*height:100vh;*/
  /*border:solid 1px;*/
}
.header-pnl {
  min-height: 72px;
  width: 100%;
  position: fixed;
  background-color: black;
  z-index: 99;
}
.header-pnl-logo {
  width: 12vw;
  margin-left: 12px;
}
.header-pnl-cart {
  padding-right: 10px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}
.header-pnl-cart-btn {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  width: 130px;
  font-size: 22px;
  display: inline-block;
  background-color: black;
  text-align: center;
  border: none;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.header-pnl-cart-txt {
  display: inline;
}
.fa-inverse {
  color: #000;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
}
.fa-layers-text {
  top: 71% !important;
}
.total-cart {
  display: none;
  width: 50vw;
  height: 70vh;
  border: solid 1px;
  z-index: 99 !important;
  position: absolute;
  background-color: grey;
}

.initial-main-div {
  height: 100%;
  width: 100%;
  margin-top: 50px;
  /*border:solid 5px;*/
}

.slider-container {
  width: 50%;
  height: 100%;
  margin-top: 50px;
  /*position:relative;
	float:left;*/
}

.img-menu-container {
  /*position:relative;
	float:left;*/
  // width: 50%;
  // height: 100%;
  // overflow-x: hidden; /* Hide horizontal scrollbar */
  // overflow-y: hidden; /* Add vertical scrollbar */
  // margin-top: 2vh;
  // position: relative !important;
  // float: left !important;
}
.img-item-box {
}
.img-in-menu {
  width: 100%;
  /* height: 160px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.footer-in-menu {
}

.footer-in-menu-text {
  position: relative;
  // float: left;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  width: 100%;
  // height: 50%;
}
.footer-in-menu img {
  width: 10px !important;
  height: 10px !important;
}
.img-cost-div {
  font-family: Arial;
  padding: 5px;
  font-size: 16px;
}
.cart {
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.cart-plus-min {
  position: absolute;
  display: none;
  width: 84px;
  height: 30px;
}
.cart-plus-min input {
  display: inline;
  margin-right: -0.5em;
  color: #6f8753;
  background-color: #fff;
  border: 1px solid #d4d5d9;
  cursor: pointer;
  text-align: center;
  height: 30px;
  width: 28px;
}

.cart-btn-div {
}
.cart-btn-div button {
  color: #6f8753;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  text-align: center;
  border: 1px solid #d4d5d9;
}
.second-main-div {
  width: 100%;
  height: 85vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (min-width: 1024px) and (max-width: 1400px) {
  .second-main-div {
    padding-top: 5vh !important;
  }
}
@media screen and (min-width: 1600px) {
  .second-main-div {
    padding-top: 20vh !important;
  }
}
@media screen and (min-width: 2300px) {
  .second-main-div {
    padding-top: 26vh !important;
  }
}

.desk-side-menu {
  position: fix;
  float: left;
  z-index: 1;
  width: 30%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  list-style-type: none;
}

.desk-side-menu ul {
  list-style-type: none;
}
.desk-side-menu button {
  background-color: Transparent;
  border: none;
  color: black;
  display: block;
  padding: 2px;
  text-decoration: none;
  font-size: 32px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  width: 135px;
  text-align: left;
}
.desk-side-menu a {
  background-color: Transparent;
  border: none;
  color: black;
  display: block;
  padding: 2px;
  text-decoration: none;
}

.desk-side-menu button:hover {
  color: #6f8753;
}
.desk-side-menu button:click {
  border: none;
  color: #6f8753;
}
.desk-side-menu button:after {
  border: none;
}
.desk-side-menu a:hover {
  color: #6f8753;
}
.desk-side-menu a:click {
  border: none;
  color: #6f8753;
}
.submenu {
  display: block;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.submenu button {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.submenu a {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.cuisine {
  margin-left: 20em;
  height: 100%;
  width: 50%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: scroll; /* Add vertical scrollbar */
  letter-spacing: 0.75px;
  padding-left: 20px;
}

.cuisine hr {
  background-color: #6f8753;
}
.cuisine ul {
  list-style-type: none;
}

.item-cat {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: auto; /* Add vertical scrollbar */
  width: 100%;
}

.item-cat-title {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.item-cat-box {
  position: relative;
  width: 350px;
  float: left;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  padding-left: 20px;
  /*display: none;*/
}
.item-row {
}
.item-name {
}
.item-name img {
  width: 10px !important;
  height: 10px !important;
  padding-top: 2px;
}
.item-details {
}
.item-descr {
  font-weight: 200;
}
.item-add button {
  color: #7be836;
  font-weight: 600;
  display: inline-block;
  background-color: #fff;
  text-align: center;
  border: 1px solid #bfb3b3;
}

/*mdl-button--floating-action*/
.flt-btn {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 94%;
  // right: 45%;
  bottom: 24px;
  margin-bottom: 0;
  z-index: 998;
  font-size: 20px;
}
.flt-btn button {
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.1em 0.1em 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #fff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.3s;
  background-color: #000;
}
.flt-btn button:hover {
  border-color: rgba(255, 255, 255, 1);
  border-radius: 2em;
}
.flt-btn button:click {
  border-color: rgba(255, 255, 255, 1);
  border-radius: 2em;
  color: #000;
  box-sizing: border-box;
}
.dropdown-menu {
  left: -33px !important;
}

.fs-16 {
  font-size: 16px;
}

.display-none {
  display: none;
}
.display-block {
  display: block;
}
.cusine-title {
  font-size: 32px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}
.cost {
  font-weight: 500;
  font-family: Arial;
}

.dropdown-item.active {
  background-color: #fff;
  color: #000;
  text-align: center;
  font-size: 20px;
}

.scroll-height {
  height: 200px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pin-code-error {
  color: red;
  /*margin-top: 0.75rem;*/
  font-size: 14px;
  font-family: "Raleway", sans-serif;
}

.modal {
  top: 200px !important;
}
.w-100 {
  height: 476px;
}

.carousel {
  height: 476px;
}

.img-menu-container {
  padding-top: 50px;
}

.center-main-div {
  height: 578px;
  margin: 0 auto;
}

.footer-in-menu img {
  margin-top: -7px !important;
  box-shadow: none;
  margin-right: 6px;
}

.footer-in-menu {
}

.img-cost-div {
  font-family: "Varela", sans-serif !important;
  font-size: 13px;
}

.item-name img {
  margin-top: -5px !important;
  box-shadow: none;
  margin-right: 6px;
}

.item-name {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .item-name {
    font-size: 22px;
  }
}

.item-descr {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .item-descr {
    font-size: 20px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cuisine::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE and Edge */
.cuisine {
  -ms-overflow-style: none !important;
  border-left: solid 1px #dadada;
  padding-left: 50px !important;
}

hr {
  background-color: #dadada !important;
}

.item-cat-box {
  padding-left: 40px;
}

.item-row {
  padding-bottom: 25px !important;
}

@media screen and (min-width: 1900px) {
  .img-item-box {
  }

  .center-main-div {
    zoom: 1.3;
  }
}

.side-menu-cuisine {
  background-color: Transparent;
  border: none;
  color: black;
  display: block;
  padding: 2px;
  text-decoration: none;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  width: 135px;
  text-align: left;
  cursor: pointer;
}
.side-menu-cuisine :hover {
  background-color: #6f8753;
}
.side-menu-cuisine :click {
  color: #6f8753;
}
.cursor-for-link {
  cursor: pointer;
}

.pincode-btn {
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.1em 0.1em 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #fff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.3s;
  background-color: #000;
}

.cursor-for-link {
  font-family: "Raleway", sans-serif;
}

li .display-block {
  padding: 4px;
  padding-left: 16px;
  font-size: 14px;
}

.bookmarkRibbon {
  width: 105px;
  height: 0;
  border-bottom: 14px solid #48c479;
  border-top: 14px solid #48c479;
  border-right: 15px solid transparent;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 700;
  position: absolute;
  top: 14px;
}

.bookmarkRibbonSpan {
  color: white;
  position: relative;
  top: -14px;
  text-align: center;
  font-size: 11px;
  right: -10px;
}

.footerBlock {
  width: 100%;
  height: auto;
  margin-top: 50px;
  min-height: 250px;
  background-color: #000;
  display: flex;
  justify-content: center;
}

.footerDivBlock {
  min-width: 390px;
  margin: 10px;
  padding: 40px;
  float: left;
}

@media screen and (max-width: 500px) {
  .footerDivBlock {
    min-height: 250px;
    margin: 10px;
    padding: 20px;
  }

  .footerBlock {
    width: 100%;
    max-height: 100%;
    height: 874px;
    background-color: #000;
    display: block;
    justify-content: center;
  }
}

@media screen and (max-width: 770px) and (min-width: 758px) {
  .slider-container {
    width: 48%;
    height: 100%;
    margin-top: 50px;
    float: left;
  }
  .img-menu-container {
  }
  .img-item-box {
  }
  .card-body {
    zoom: 0.9;
  }
}

.p_footer {
  width: 100%;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}
.p_footer_body {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: justify;
  text-justify: inter-word;
  color: white;
  display: flex;
}
.line_div {
  width: 170px;
  height: 2px;
  margin-top: -10px;
  margin-bottom: 15px;
  background-color: green;
}
.socialMediablock {
  width: 40px;
  height: 40px;
  margin: 5px;
}

.socialMediablockImg {
  width: 100%;
  height: 100%;
}
.lg_bright {
  background-color: white;
  color: black;
}

@media screen and (max-width: 368px) and (min-width: 300px) {
  .card-body {
    zoom: 0.9;
  }
}
/*Cart - Order page classes - START - Mihir */
.cart-main-div {
  height: 100%;
  width: 100%;
  margin-top: 90px;
}
.cart-title {
  width: 100%;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  font-size: 28px;
  padding-left: 1.8em;
}
.cart-table {
  width: 100%;
  font-family: "Raleway", sans-serif;
  padding-left: 3em;
  padding-right: 3em;
}
.cart-table thead {
  background-color: #000000;
  color: #ffffff;
}
.cart-div {
  color: #7be836;
  cursor: pointer;
  font-weight: 600;
  background-color: #fff;
  text-align: center;
  border: 1px solid #bfb3b3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-txt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-icon-plus {
  border-left: 1px solid #d4d5d9;
  font-size: 13px;
}
.cart-icon-minus {
  border-right: 1px solid #d4d5d9;
  font-size: 13px;
}

.address-container {
  width: 50%;
  height: 100%;
}
.address-title {
  width: 100%;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  font-size: 30px;
  padding-left: 1.8em;
}
.address-inputs {
  width: 100%;
  padding-left: 3em;
  padding-right: 3em;
}
.invoice-container {
  width: 50%;
  height: 100%;
  padding-right: 2em;
}
.invoice-title {
  width: 100%;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invoice-distrib {
  width: 100%;
  padding-left: 10em;
}

.invoice-distrib td {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}
.invoice-btns-container {
  width: 100%;
  height: 20%;
  padding-left: 8em;
}
.payment-btn {
  color: #fff;
  padding: 0.3em 1.2em;
  margin: 0 0.1em 0.1em 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  width: 100%;
  border-radius: 2px;
  text-decoration: none;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  color: #fff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.3s;
  background-color: #000;
}
.payment-btn:hover {
  text-decoration: none;
}
/*Cart - Order page classes - START - Mihir */

/*Popeover Cart  classes START Mihir*/
.popover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.78), 0 6px 20px 0 rgba(0, 0, 0, 0.82) !important;
}
.pop-body {
  width: 250px;
  max-height: 400px;
  overflow-y: auto;
}
.pop-footer {
  width: 250px;
  height: 20%;
}

.popover-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.popover-body ::-webkit-scrollbar {
  width: 5px !important;
}

.popover-body ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.popover-body ::-webkit-scrollbar-thumb {
  background: #888888 !important;
}

.popover-body td {
  padding: 0px !important;
}
.popover-body hr {
  border-top: 1.5px dashed rgba(0, 0, 0, 0.3) !important;
}
.pop-footer-total {
  width: 100%;
  height: 50%;
  margin-top: 1.5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: bolder;
}
.pop-footer-btn-div {
  width: 100%;
  height: 50%;
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: center; // margin-top: 10vh;

  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.1em 0.1em 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  width: 240px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #fff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.3s;
  background-color: #000;
  // margin-top: 10vh;
}
.pop-foot-btn:hover {
  text-decoration: none;
}
.pointer-events-none {
  pointer-events: none;
  background-color: #888888;
}
.pointer-events-auto {
  pointer-events: auto;
}
/*Popeover Cart  classes END Mihir*/
/*OrderConfirm - Order Confirmation page classes - START - Mihir */
.order-main-div {
  height: 100%;
  width: 100%;
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1600px) {
  .order-main-div {
    margin-top: 140px !important;
  }
}
.order-final-bill {
  width: 350px;
  height: 100%;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.bill-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.bill-title {
  width: 100%;
  display: flex;
  align-items: center;
  // margin-top: 10vh;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}
.bill-address {
  width: 100%;
  font-size: 16px;
  margin-top: 1em;
}
.bill-mob {
  // margin-top: 10vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.bill-name {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-top: 1em;
  margin-bottom: 1em;
}
.bill-orderno {
  width: 100%;
  font-size: 14px;
}
/*OrderConfirm - Order Confirmation page classes - END - Mihir */
.loader {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loader circle {
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    // margin-top: 10vh;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.social-media-logo-size {
  font-size: 30px;
  text-decoration: none;
  color: white;
}

.arial-font {
  font-family: Arial, Helvetica, sans-serif;
}

/* .carousel-caption {
  background-color: rgba(97, 95, 95, 0.4) !important;
  left: none !important;
  right: none !important;
  width: 100% !important;
} */

.grand-total {
  font-size: 28px;
  font-weight: 700;
}

.order-fail {
  padding-top: 110px;
  text-align: center;
  min-height: 400px;
}

// Fix for mobile view modal background
.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.customizable {
  font-size: 11px;
  margin-top: 2px;
  color: #a1a1a1;
}

.form-check {
  padding-bottom: 10px;
}

.payment-partner {
  width: 80px;
  margin-left: 10px;
}
